import { computed } from "vue";
import store from "@/store/index";

/**
 * Returns applications
 * @returns array
 */
export const companies = computed(() => {
  return store.getters.currentCompanies;
});
