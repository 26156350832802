
import { defineComponent } from "vue";
import FullCalendar, { CalendarOptions, EventApi } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import moment from "moment";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { locale } from "@/core/helpers/config";
import enLocale from "@fullcalendar/core/locales/en-au";
import arLocale from "@fullcalendar/core/locales/ar-kw";

export default defineComponent({
  components: {
    FullCalendar,
  },
  data() {
    const todayDate = moment().startOf("day");
    const TODAY = todayDate.format("YYYY-MM-DD");

    return {
      calendarKey: 0,
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },

        height: 800,
        contentHeight: 780,
        aspectRatio: 3, // see: https://fullcalendar.io/docs/aspectRatio

        nowIndicator: true,
        now: TODAY + "T09:25:00", // just for demo

        initialView: "dayGridMonth",
        initialDate: TODAY,
        locale: locale() == "ar" ? arLocale : enLocale,
        editable: true,
        dayMaxEvents: true, // allow "more" link when too many events
        navLinks: true,
        events: [],
      } as CalendarOptions,
      currentEvents: [] as EventApi[],
    };
  },
  created() {
    store.dispatch(Actions.GET_LATEST_EVENTS).then((data) => {
      this.calendarOptions.events = data;
    });
  },
});
