
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { companies } from "@/core/helpers/companies";
import { tables_statistics } from "@/core/helpers/statistics";

export default defineComponent({
  name: "kt-widget-2",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t } = useI18n();

    store.dispatch(Actions.GET_COMPANIES, { top: true });

    return {
      t,
      companies,
      tables_statistics,
    };
  },
});
