
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { usersLineChart } from "@/core/helpers/statistics";
import { useI18n } from "vue-i18n";
import { tables_statistics } from "@/core/helpers/statistics";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "widget-12",
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
  },
  data() {
    return {
      type: "monthly",
    };
  },
  computed: {
    chartOptions: function () {
      const labelColor = getCSSVariableValue("--bs-" + "gray-800");
      const strokeColor = getCSSVariableValue("--bs-" + "gray-300");
      let categories: Array<string> = [];

      if (this.type == "weekly")
        categories = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
      if (this.type == "monthly")
        categories = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
      if (this.type == "yearly") {
        categories = ["2021", "2022", "2023", "2024", "2025", "2026", "2027"];
      }

      return {
        chart: {
          fontFamily: "inherit",
          chart: {
            fontFamily: "inherit",
            type: "bar",
            height: this.$props.chartHeight,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: ["50%"],
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
        },
        colors: ["#247BA0", "#FF1654"],
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          colors: ["#247BA0", "#FF1654"],
        },
        xaxis: {
          categories,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
          crosshairs: {
            show: false,
            position: "front",
            stroke: {
              color: strokeColor,
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {},
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      };
    },
  },
  methods: {
    fetchUsersChart(type) {
      store.dispatch(Actions.GET_USER_LINE_CHART, type);
      this.type = type;
      if (type == "weekly")
        this.chartOptions.xaxis.categories = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
      if (type == "monthly")
        this.chartOptions.xaxis.categories = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
      if (type == "yearly") {
        this.chartOptions.xaxis.categories = [
          "2021",
          "2022",
          "2023",
          "2024",
          "2025",
          "2026",
          "2027",
        ];
      }
    },
  },
  setup() {
    const { t, te } = useI18n();

    const translate = (text: string) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      usersLineChart,
      tables_statistics,
      translate,
    };
  },
});
