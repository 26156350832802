import { computed } from "vue";
import store from "@/store/index";

/**
 * Returns applications
 * @returns array
 */
export const invoices = computed(() => {
  return store.getters.currentInvoices;
});
