
import { defineComponent, onMounted } from "vue";
import InvoicesCard from "@/views/apps/dashboard/InvoicesCard.vue";
import CompaniesCard from "@/views/apps/dashboard/CompaniesCard.vue";
import Statistics from "@/views/apps/dashboard/Statistics.vue";
import UsersChart from "@/views/apps/dashboard/UsersChart.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { authUser } from "@/core/helpers/users";
import Calendar from "@/views/apps/dashboard/Calendar.vue";

export default defineComponent({
  name: "dashboard",
  components: {
    Calendar,
    InvoicesCard,
    CompaniesCard,
    Statistics,
    // InvoicesChart,
    UsersChart,
  },
  setup() {
    const { t } = useI18n();

    store.dispatch(Actions.GET_STATISTICS, { type: "monthly" });
    store.dispatch(Actions.GET_PAID_INVOICE_LINE_CHART);
    store.dispatch(Actions.GET_PENDING_INVOICE_LINE_CHART);
    store.dispatch(Actions.GET_USER_LINE_CHART);

    onMounted(() => {
      setCurrentPageTitle(t("nav.statistics"), "bx bx-line-chart");
    });

    return {
      authUser,
    };
  },
});
