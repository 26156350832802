import { computed } from "vue";
import store from "@/store/index";

/**
 * Returns applications
 * @returns array
 */
export const invoices_statistics = computed(() => {
  if (!store.getters.currentStatistics) return {};

  const target = [
    "pending_invoices",
    "pending_amount",
    "paid_invoices",
    "paid_amount",
    "returned_invoices",
    "returned_amount",
  ];

  const statistics = {};

  for (const key of store.getters.currentStatistics) {
    if (target.includes(key.key)) statistics[key.key] = key.value;
  }
  return statistics;
});

export const tables_statistics = computed(() => {
  if (!store.getters.currentStatistics) return {};

  const target = [
    "total_companies",
    "total_users",
    "total_countries",
    "total_invoices",
  ];

  const statistics = {};

  for (const key of store.getters.currentStatistics) {
    if (target.includes(key.key)) statistics[key.key] = key.value;
  }
  return statistics;
});

export const usersLineChart = computed(() => {
  return store.getters.usersLineChart
    ? [{ name: "Users", data: store.getters.usersLineChart.series }]
    : [{ name: "", data: [] }];
});

export const invoicesLineChart = computed(() => {
  return store.getters.invoicesPaidLineChart &&
    store.getters.invoicesPaidLineChart
    ? [
        {
          name: "Paid Invoices",
          data: store.getters.invoicesPaidLineChart.series,
        },
        {
          name: "Pending Invoices",
          data: store.getters.invoicesPendingLineChart
            ? store.getters.invoicesPendingLineChart.series
            : [],
        },
      ]
    : [{ name: "", data: [] }];
});
