
import { defineComponent } from "vue";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import { invoices } from "@/core/helpers/invoices";
import { copyLink } from "@/core/helpers/config";
declare const window: any;

export default defineComponent({
  name: "invoices-card",
  components: {},
  props: {
    widgetClasses: String,
  },
  methods: {
    toReceipt(id) {
      window.open(
        window.domainName +
          "/invoices/pdf/" +
          id +
          "?locale=" +
          this.$i18n.locale,
        "_blank"
      );
    },
  },
  setup() {
    const { t, te } = useI18n();

    const translate = (text: string) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    store.dispatch(Actions.GET_INVOICES);

    return {
      domainName: window.domainName,
      translate,
      invoices,
      copyLink,
    };
  },
});
