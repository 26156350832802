
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { invoices_statistics } from "@/core/helpers/statistics";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "statistics",
  components: {},
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
  },
  data() {
    return {
      type: "monthly",
    };
  },
  methods: {
    fetchStatistics(type) {
      store.dispatch(Actions.GET_STATISTICS, { type });
      this.type = type;
    },
  },
  setup() {
    const { t, te } = useI18n();

    const translate = (text: string) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      invoices_statistics,
      translate,
    };
  },
});
