import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60a82712"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-body pt-2" }
const _hoisted_2 = { class: "d-flex flex-stack mb-5" }
const _hoisted_3 = { class: "d-flex align-items-center me-2" }
const _hoisted_4 = {
  href: "#",
  class: "fs-6 text-gray-800 text-hover-primary fw-bolder"
}
const _hoisted_5 = { class: "badge badge-light fw-bolder fs-3 py-4 px-3" }
const _hoisted_6 = { class: "card-body pt-2" }
const _hoisted_7 = { class: "symbol symbol-50px me-5" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "flex-grow-1" }
const _hoisted_10 = { class: "text-muted text-start d-block fw-bold phone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t("chart.total_companies")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.tables_statistics && _ctx.tables_statistics.total_companies
                ? _ctx.tables_statistics.total_companies
                : 0), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companies, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass([{ 'mb-7': _ctx.companies.length - 1 !== index }, "d-flex align-items-center"])
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              src: item.image.url,
              class: "",
              alt: ""
            }, null, 8, _hoisted_8)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_router_link, {
              to: { name: 'edit-companies', params: { id: item.encrypt_id } },
              class: "text-dark fw-bolder text-hover-primary fs-6"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name) + " ", 1),
                _createElementVNode("span", _hoisted_10, _toDisplayString(item.country_code + item.phone), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ], 2))
      }), 128))
    ])
  ], 2))
}