import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = {
  class: "card-toolbar",
  "data-kt-buttons": "true"
}
const _hoisted_5 = { class: "card-body p-0 d-flex justify-content-between flex-column overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("chart.users")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", {
          class: _normalizeClass([_ctx.type == 'weekly' ? 'active' : '', "btn btn-sm btn-color-muted btn-active btn-active-primary px-4"]),
          id: "kt_charts_widget_2_week_btn",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fetchUsersChart('weekly')))
        }, _toDisplayString(_ctx.$t("app.weekly")), 3),
        _createElementVNode("a", {
          class: _normalizeClass([_ctx.type == 'monthly' ? 'active' : '', "btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1"]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchUsersChart('monthly'))),
          id: "kt_charts_widget_2_month_btn"
        }, _toDisplayString(_ctx.$t("app.monthly")), 3),
        _createElementVNode("a", {
          class: _normalizeClass([_ctx.type == 'yearly' ? 'active' : '', "btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1"]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchUsersChart('yearly'))),
          id: "kt_charts_widget_2_year_btn"
        }, _toDisplayString(_ctx.$t("app.yearly")), 3)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.usersLineChart)
        ? (_openBlock(), _createBlock(_component_apexchart, {
            key: 0,
            class: "mixed-widget-10-chart",
            options: _ctx.chartOptions,
            series: _ctx.usersLineChart,
            type: "bar",
            height: _ctx.chartHeight
          }, null, 8, ["options", "series", "height"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}